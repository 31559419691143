{
  "locale": "en",
  "iso": "en_US",
  "meta": {
    "title": {
      "internalWallet": "This is an internal wallet",
      "externalWallet": "This is an external wallet",
      "2fa": "Two-factor authentication protects your account",
      "rating": "Rating Leaders",
      "refund": "Refund Guarantee"
    }
  },
  "internalWallet": {
    "title1": "This is an internal wallet",
    "text1": "Your income will be credited here",
    "title2": "With internal wallet you can:",
    "list1": [
      {
        "src": "icon-wallet.svg",
        "alt": "Icon wallet",
        "text": "Transfer LEE, CHEEL and USDT to an external wallet. A commission will be deducted during transfer"
      },
      {
        "src": "icon-history.svg",
        "alt": "Icon history",
        "text": "Sell LEE for fiat currency"
      },
      {
        "src": "icon-buy.svg",
        "alt": "Icon buy",
        "text": "Pay for in-app purchases"
      }
    ],
    "text2": "In the internal wallet, transactions are possible with these tokens:",
    "list2": [
      {
        "src": "icon-cheel.png",
        "alt": "Icon cheel",
        "text": "CHEEL"
      },
      {
        "src": "icon-lee.png",
        "alt": "Icon lee",
        "text": "LEE"
      },
      {
        "src": "icon-usdt.png",
        "alt": "Icon usdt",
        "text": "USDT"
      }
    ],
    "text3": "EASY coin will be available for withdrawal or sale as soon as it appears on exchanges. Stay tuned for this news in our community!",
    "button":{
      "text":  "More",
      "link": "https://intercom.help/cheelee/en/articles/8973167-how-to-use-the-internal-wallet"
    }
  },
  "externalWallet": {
    "title1": "It's an external wallet",
    "text1": "Coins from your Cheelee internal wallet or third-party cryptocurrency wallets can be transferred here",
    "title2": "You can use it to:",
    "list1": [
      {
        "src": "icon-arrow-diagonal-out.svg",
        "alt": "Icon wallet",
        "text": "Withdraw income from the app"
      },
      {
        "src": "icon-arrow-diagonal-in.svg",
        "alt": "Icon refill",
        "text": "Refill your balance with cryptocurrency from other services"
      },
      {
        "src": "icon-arrow-round-back.svg",
        "alt": "Icon history",
        "text": "Check your transaction history"
      }
    ],
    "text2": "A commission in BNB will be charged for transfers from the internal wallet and when withdrawing funds",
    "list2": [
      {
        "src": "icon-bnb.svg",
        "alt": "Icon bnb",
        "text": "BNB"
      }
    ],
    "button": {
      "text": "More",
      "link": "https://intercom.help/cheelee/en/articles/8973315-how-to-use-an-external-wallet"
    }
  },
  "2fa": {
    "title1": "Two-factor authentication protects your account",
    "text": "When you sign in to your account from an unknown browser or device, we will ask for a password",
    "title2": "How to turn it on?",
    "list": [
      {
        "src": "img-webview-5-1.png",
        "srcset": "img-webview-5-1@2x.png",
        "alt": "Icon",
        "text": "1. Tap <b>Profile</b> in the lower right corner of the screen"
      },
      {
        "src": "img-webview-5-2.png",
        "srcset": "img-webview-5-2@2x.png",
        "alt": "Icon",
        "text": "2. Tap the icon in the upper right corner of the screen, and then go to <b>Settings</b>"
      },
      {
        "src": "img-webview-5-3.png",
        "srcset": "img-webview-5-3@2x.png",
        "alt": "Icon",
        "text": "3. Tap <b>Security</b>"
      },
      {
        "src": "img-webview-5-4.png",
        "srcset": "img-webview-5-4@2x.png",
        "alt": "Icon",
        "text": "4. Turn on <b>Two-factor authentication</b>"
      },
      {
        "text": "5. Follow the instructions"
      }
    ]
  },
  "rating": {
    "text1": "Income",
    "button": "Make money like that!"
  },
  "refund": {
    "title": "REFUND GUARANTEE",
    "text1": "Glasses won’t pay off in 28 days? We’ll give you twice as much",
    "listTitle": "Refund terms:",
    "text2": "Glasses pay off – or we’ll refund x2 of the price.",
    "list": [
      {
        "text": "If glasses or case were purchased on or after April, 26, 2024"
      },
      {
        "text": "If glasses/case were bought not with LEE coins"
      },
      {
        "text": "If account is not marked by «suspicious activity» tag"
      },
      {
        "text": "If minutes of paid viewing were fully spent for 4 weeks in a row (days of technical failures do not count)"
      }
    ],
    "text3": "Refund will be in 14 business days, to internal wallet, in USDT. 2x price of glasses or case, minus the coins earned. Glasses will be broken before the payout.",
    "button": "Get a refund"
  },
  "lottery": {
    "text1": "здесь будут твои шансы",
    "text2": "Чем больше билетов, тем больше шансов выиграть!",
    "text3": "your Chances",
    "text4": "The giveaway starts",
    "text5": "Трансляция уже началась!",
    "text6": "Розыгрыш завершен",
    "text7": "Выиграй тачку, деньги или&nbsp;смартфон!",
    "text8": "Собирай шансы за&nbsp;покупки и&nbsp;активности — повышай вероятность выиграть",
    "text9": "$$$ or a car?<br>Win top prizes with Cheelee!",
    "text10": "Shop, collect chances, and&nbsp;win&nbsp;big!",
    "text11": "on",
    "tickets": {
      "text1": "номера твоих</br>шансов",
      "text2": "номера призовых</br>шансов",
      "text3": "В ячейках указаны номера всех твоих шансов",
      "text4": "Среди них могут быть призовые!",
      "text5": "Поздравляем, у тебя есть призовой шанс!",
      "text6": "Свяжись с командой поддержки и&nbsp;узнай, как&nbsp;получить приз",
      "text7": "К сожалению, в этот раз&nbsp;у&nbsp;тебя нет призовых шансов",
      "text8": "Жди следующего розыгрыша!",
      "text9": "Номера шансов и&nbsp;призы",
      "text10": "Car"
    },
    "timer": {
      "text1": "days",
      "text2": "hours",
      "text3": "minutes",
      "text4": "seconds"
    },
    "steps": {
      "text1": "Step 1",
      "text2": "Step 2",
      "text3": "Step 3",
      "text4": "Purchase glasses or lootboxes to get giveaway Chances",
      "text5": "<b>The rarer the glasses and lootboxes, the more Chances you get!</b>",
      "text6": "Register your purchase <b>to get Chances*</b>",
      "text7": "Our team <b>will confirm your purchase and you’ll get Chances</b>",
      "text8": "Join the Cheelee Telegram community!",
      "text9": "<b>Stay tuned and participate in cool activities</b>",
      "text10": "How will winners be chosen?",
      "text11": "Winners will be randomly chosen and announced live",
      "text12": "The full list will be published in&nbsp;the&nbsp;app"
    },
    "button": {
      "text1": "смотреть победителей",
      "text2": "закрыть",
      "text3": "go to marketplace",
      "text4": "chances",
      "text5": "join now",
      "text6": "full Giveaway Rules",
      "text7": "Посмотреть номера",
      "text8": "смотреть online",
      "text9": "связаться с поддержкой"
    }
  }
}
