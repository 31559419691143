import {Routes, UrlMatchResult, UrlSegment} from '@angular/router';
import {WEBVIEW_PATHS} from "@core/data/data";

export const routes: Routes = [
  {
    path: ':lng',
    children: [
      {path: 'webview-1', redirectTo: 'internalWallet'},
      {path: 'webview-1.html', redirectTo: 'internalWallet'},
      {path: 'webview-2', redirectTo: 'externalWallet'},
      {path: 'webview-2.html', redirectTo: 'externalWallet'},
      {path: 'webview-5', redirectTo: '2fa'},
      {path: 'webview-5.html', redirectTo: '2fa'},
      {path: 'webview-5-android', redirectTo: '2fa'},
      {path: 'webview-5-android.html', redirectTo: '2fa'},
      {
        path: 'lottery',
        loadComponent: () => import('./pages/lottery/lottery.component').then(c => c.LotteryComponent)
      },
      {
        path: 'roulette',
        loadComponent: () => import('./pages/roulette/roulette.component').then(c => c.RouletteComponent)
      },
      {
        path: ':name',
        loadComponent: () => import('./pages/webview/webview.component').then(c => c.WebviewComponent)
      }
    ]
  },
];
