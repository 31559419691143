import {
  defaultInterpolationFormat,
  I18NEXT_NAMESPACE,
  I18NEXT_SERVICE,
  I18NextLoadResult,
  I18NextModule,
  ITranslationService,
} from 'angular-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { InitOptions } from 'i18next';
import { APP_INITIALIZER, LOCALE_ID, Provider } from '@angular/core';
import enTranslates from '../../../../src/assets/locales/en.json';
import ruTranslates from '../../../../src/assets/locales/ru.json';

export enum LanguageLocale {
  EN = 'en-US',
  RU = 'ru-RU',
}
export const DEFAULT_LANGUAGE = LanguageLocale.EN;

const i18nextOptions: InitOptions = {
  resources: {
    [LanguageLocale.EN]: {
      translations: enTranslates
    },
    [LanguageLocale.RU]: {
      translations: ruTranslates,
    },
  },
  fallbackLng: {
    'en-US': [ LanguageLocale.EN ],
    'ru-RU': [ LanguageLocale.RU ],
    default: [ DEFAULT_LANGUAGE ],
  },
  // languages: ['ru', 'en'],
  supportedLngs: Object.values(LanguageLocale),
  returnEmptyString: false,
  // debug: !environment.production,

  // have a common namespace used around the full app
  defaultNS: 'translations',
  load: 'currentOnly',
  keySeparator: '.', // we use content as keys
  nsSeparator: ':',
  interpolation: {
    format: I18NextModule.interpolationFormat(defaultInterpolationFormat),
  },

  // lang detection plugin options
  detection: {
    // order and from where users language should be detected
    order: [ 'path' ],
    lookupFromPathIndex: 0,
    lookupFromSubdomainIndex: 0,
    caches: [ 'localStorage' ],
    excludeCacheFor: [ 'cimode' ], // languages to not persist (cookie, localStorage)
    cookieMinutes: 0,
    cookieDomain: '/',
  },
};

//  Инициализация i18next
export function appInit(i18next: ITranslationService): () => Promise<I18NextLoadResult | void> {
  return () => i18next
    .use(LanguageDetector)
    .init(i18nextOptions);
}

export function localeIdFactory(i18next: ITranslationService): string {
  return i18next.language;
}

export const I18N_PROVIDERS: Provider[] = [
  {
    provide: APP_INITIALIZER,
    useFactory: appInit,
    deps: [ I18NEXT_SERVICE ],
    multi: true,
  },
  {
    provide: I18NEXT_NAMESPACE,
    useValue: ['translations', 'validationErrors'],
  },
  {
    provide: LOCALE_ID,
    deps: [ I18NEXT_SERVICE ],
    useFactory: localeIdFactory,
  },
];
